<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗牧业排行</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <!-- 种植种类 -->
        <div class="left_cover_box">
          <div class="title"><span>{{ activeVillage }}养殖排行</span></div>
          <bing-tu class="chart" :value="data1" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_cover_box">
          <ba-lin-you-qi-map @changeVillage="changeVillage" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="pai_hang">
          <div class="title"><span>排行</span></div>

          <div class="tab_box">
            <div class="tab_item" :class="{ activeColor: activeIndex == 0 }" @click="clickTab('0')">牛</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 1 }" @click="clickTab('1')">马</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 2 }" @click="clickTab('2')">山羊</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 3 }" @click="clickTab('3')">绵羊</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 4 }" @click="clickTab('4')">毛驴</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 5 }" @click="clickTab('5')">猪</div>
          </div>

          <div class="list_box">
            <div class="list_item">
              <div class="activeColor">序号</div> 
              <div class="activeColor">苏木（镇）</div> 
              <div class="activeColor">排行</div>
              <div class="activeColor">存栏数量（头）</div> 
            </div>
            <div class="list_item" v-for="(item, index) in list" :key="index">
              <span class="ml_10" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="ml_10" v-else>{{ index+1 }}</span> 

              <div class="name" @click="clickVillageName(item.name)">{{ item.name }}</div> 

              <div class="bgc">
                <div class="c" :style="{width: (item.value / 28) + '%'}"></div>
              </div>

              <div class="value font_number mr_10">{{ item.value }}</div> 
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
    },
    data() {
      return {
        activeIndex: 0,
        activeVillage: '索博日嘎镇',
        data1: {},

        list: [
          {name: "索博日嘎镇", value: 853},
          {name: "幸福之路苏木", value: 773},
          {name: "巴彦琥硕镇", value: 655},
          {name: "查干沐沦苏木", value: 550},
          {name: "大板镇", value: 498},
          {name: "大板煤电化基地", value: 423},
          {name: "巴彦塔拉苏木", value: 339},
          {name: "查干诺尔镇", value: 286},
          {name: "宝日勿苏镇", value: 195},
          {name: "西拉沐沦苏木", value: 155}   
        ],
        list1: [
          {name: "索博日嘎镇", value: 853},
          {name: "幸福之路苏木", value: 773},
          {name: "巴彦琥硕镇", value: 655},
          {name: "查干沐沦苏木", value: 550},
          {name: "大板镇", value: 498},
          {name: "大板煤电化基地", value: 423},
          {name: "巴彦塔拉苏木", value: 339},
          {name: "查干诺尔镇", value: 286},
          {name: "宝日勿苏镇", value: 195},
          {name: "西拉沐沦苏木", value: 155}   
        ],

        list2: [
          {name: "索博日嘎镇", value: 244},
          {name: "幸福之路苏木", value: 253},
          {name: "巴彦琥硕镇", value: 184},
          {name: "查干沐沦苏木", value: 540},
          {name: "大板镇", value: 675},
          {name: "大板煤电化基地", value: 753},
          {name: "巴彦塔拉苏木", value: 653},
          {name: "查干诺尔镇", value: 735},
          {name: "宝日勿苏镇", value: 455},
          {name: "西拉沐沦苏木", value: 225}   
        ],

        list3: [
        {name: "索博日嘎镇", value: 124},
          {name: "幸福之路苏木", value: 523},
          {name: "巴彦琥硕镇", value: 214},
          {name: "查干沐沦苏木", value: 550},
          {name: "大板镇", value: 245},
          {name: "大板煤电化基地", value: 773},
          {name: "巴彦塔拉苏木", value: 633},
          {name: "查干诺尔镇", value: 415},
          {name: "宝日勿苏镇", value: 195},
          {name: "西拉沐沦苏木", value: 155}   
        ],

        list4: [
          {name: "索博日嘎镇", value: 74},
          {name: "幸福之路苏木", value: 523},
          {name: "巴彦琥硕镇", value: 454},
          {name: "查干沐沦苏木", value: 50},
          {name: "大板镇", value: 655},
          {name: "大板煤电化基地", value: 473},
          {name: "巴彦塔拉苏木", value: 553},
          {name: "查干诺尔镇", value: 455},
          {name: "宝日勿苏镇", value: 465},
          {name: "西拉沐沦苏木", value: 155}   
        ],

        list5: [
          {name: "索博日嘎镇", value: 24},
          {name: "幸福之路苏木", value: 523},
          {name: "巴彦琥硕镇", value: 414},
          {name: "查干沐沦苏木", value: 50},
          {name: "大板镇", value: 155},
          {name: "大板煤电化基地", value: 173},
          {name: "巴彦塔拉苏木", value: 353},
          {name: "查干诺尔镇", value: 115},
          {name: "宝日勿苏镇", value: 195},
          {name: "西拉沐沦苏木", value: 155}   
        ],

        list6: [
          {name: "索博日嘎镇", value: 124},
          {name: "幸福之路苏木", value: 523},
          {name: "巴彦琥硕镇", value: 134},
          {name: "查干沐沦苏木", value: 550},
          {name: "大板镇", value: 365},
          {name: "大板煤电化基地", value: 773},
          {name: "巴彦塔拉苏木", value: 453},
          {name: "查干诺尔镇", value: 115},
          {name: "宝日勿苏镇", value: 125},
          {name: "西拉沐沦苏木", value: 155}   
        ]
      }
    },
    mounted() {
      this.hamletName = this.$route.params.val
      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '养殖',
          unit: '头',
          data: [
            {"name": "牛", "value": 275, "unit": ''},
            {"name": "马", "value": 243, "unit": ''},
            {"name": "山羊", "value": 556, "unit": ''},
            {"name": "绵羊", "value": 135, "unit": ''},
            {"name": "毛驴", "value": 255, "unit": ''},
            {"name": "猪", "value": 425, "unit": ''},
          ]
        }
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1.sort((a, b) => b.value - a.value)
        } else if (val == 1) {
          this.list = this.list2.sort((a, b) => b.value - a.value)
        } else if (val == 2) {
          this.list = this.list3.sort((a, b) => b.value - a.value)
        } else if (val == 3) {
          this.list = this.list4.sort((a, b) => b.value - a.value)
        } else if (val == 4) {
          this.list = this.list5.sort((a, b) => b.value - a.value)
        } else if (val == 5) {
          this.list = this.list6.sort((a, b) => b.value - a.value)
        }
      },

      // 点击 乡镇名称 后跳转
      clickVillageName(val) {
        this.activeVillage = val
        
        for (let i in this.data1.data) {
          if (this.data1.data[i].value > 200) {
            this.data1.data[i].value -= 83
          } else {
            this.data1.data[i].value += 98
          }
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      changeVillage(val) {
        for (let i in this.data1.data) {
          if (this.data1.data[i].value > 200) {
            this.data1.data[i].value -= 83
          } else {
            this.data1.data[i].value += 98
          }
        }
      } 
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }

  
  .content_item_box { width: 33.333333% !important; }
  .left_cover_box { 
    height: 100% !important;

    .title { color: #fff; text-align: center; padding-top: 12px; font-size: 20px; font-weight: 600; }
  }
  .map_cover_box { height: 100% !important; }

</style>